import { hitOnce, hits } from "../tools/hits"
import { logEvent, userEvents } from "../tools/log"
import { gaEvents, gaSendEvent } from "../tools/ga"
import { logEvent as amplitudeLogEvent } from "../tools/amplitude"
import config from "../config"

const scrollElements = document.querySelectorAll(".reveal")

let options = {
	threshold: 0.1,
}
const observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			entry.target.classList.add("animated")
			observer.unobserve(entry.target)
		}
	})
}, options)

const loadedTime = new Date().toLocaleTimeString().replace(/(\d+:\d{2})(:\d{2})(.*)/, "$1$3")

logEvent("page_" + (document.body.dataset.page || "unknown"))

window.toonAppData = {}

function onPageLoad() {
	const eventParams = {
		full: window.location.href,
		host: window.location.host,
		path: window.location.path,
		queryString: window.location.search,
	}

	logEvent("page_load", eventParams)
	amplitudeLogEvent("page_load", eventParams)
}

function onElementClick(e) {
	const nodeName = e.target.nodeName.toLowerCase()
	let path = []
	try {
		path = getElementDomPath(e.target)
	} catch (e) {
		console.error(e)
	}

	const eventParams = {
		nodeName,
		path: path.join(" > "),
	}

	if (nodeName === "a" || nodeName === "button") {
		eventParams.content = e.target.innerText
	}

	logEvent("page_click", eventParams)
	amplitudeLogEvent("page_click", eventParams)
}

function getElementDomPath(el) {
	const stack = []
	while (el.parentNode != null) {
		let sibCount = 0
		let sibIndex = 0
		for (let i = 0; i < el.parentNode.childNodes.length; i++) {
			let sib = el.parentNode.childNodes[i]
			if (sib.nodeName === el.nodeName) {
				if (sib === el) {
					sibIndex = sibCount
				}
				sibCount++
			}
		}

		if (el.hasAttribute("id") && el.id !== "") {
			stack.unshift(el.nodeName.toLowerCase() + "#" + el.id)
		} else if (sibCount > 1) {
			stack.unshift(el.nodeName.toLowerCase() + ":eq(" + sibIndex + ")")
		} else {
			stack.unshift(el.nodeName.toLowerCase())
		}

		el = el.parentNode
	}

	return stack.slice(1)
}

if (document.readyState === "complete") {
	onPageLoad()
} else {
	document.addEventListener("readystatechange", () => {
		if (document.readyState === "complete") {
			onPageLoad()
		}
	})
}

document.addEventListener("click", onElementClick)

document.addEventListener("DOMContentLoaded", function () {
	scrollElements.forEach((el) => observer.observe(el))
	hitOnce(hits.visit)

	if (window.ethereum) {
		hitOnce(hits.visitWithMetamask)
	}

	const reachedPlaces = []
	setInterval(() => {
		const scrollTop = window.pageYOffset
		const windowHeight = window.innerHeight
		const scrollPlaceElements = document.querySelectorAll("[data-scroll-place-id]")
		scrollPlaceElements.forEach((item) => {
			const placeId = item.dataset.scrollPlaceId
			const placeTop = item.offsetTop

			if (placeTop < scrollTop + windowHeight / 2) {
				if (reachedPlaces.indexOf(placeId) === -1) {
					reachedPlaces.push(placeId)
					gaSendEvent(gaEvents.scrollPlace, { place: placeId })
					logEvent(userEvents.scrollPlace, { place: placeId })
				}
			}
		})
	}, 100)
})
