import {queryStringToObject} from "./tools/url";

const query = queryStringToObject(window.location.search.substring(1));
const isDebug = !!query["debug"];
const isProduction = true;

const config = {
	query: query,
	isDebug: isDebug,
	isProduction: isProduction,
	isMobile: /iphone|ipad|android/i.test(window.navigator.userAgent),
	locale: query["locale"] || query["lang"] || window.navigator.language || undefined,
	services: {
		amplitude: {
			key: "d12f46bfe20777d8e7ad7481e1446b46",
		},
	},
}

export default config;
